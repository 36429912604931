export const bizstructures = [
  {
    'id': '1',
    'name': 'დამწყები',
  },
  {
    'id': '2',
    'name': 'ელემენტარული',
  },
  {
    'id': '3',
    'name': 'საშუალო',
  },
  {
    'id': '4',
    'name': 'მაღალი',
  },
  
];
