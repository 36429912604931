import * as Yup from 'yup';

const GMCSchemaStepTwo = Yup.object().shape({
  age: Yup.string()
    .min(2, 'მიუთითეთ თქვენი ასაკი')
    .max(2, 'მიუთითეთ თქვენი ასაკი')
    .required('მიუთითეთ თქვენი ასაკი'),
  
  
  // firstTime: Yup.string()
  //   .required('Please select one'),
  // sumInsuredType: Yup.string().when('firstTime', {
  //   is: true,
  //   then: schema => schema.required('Please select one'),
  // }),
   bizstructure: Yup.string().required('მიუთითეთ ინგლისური ენის ცოდნის დონე'),
});

export default GMCSchemaStepTwo;