import * as Yup from 'yup';

export const emailRegex = /((?:[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*(?:[\w\!\#$\%\'\*\+\-\/\=\?\^\`{\|\}\~]|&amp;)+)@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,63})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)/i;

const GMCSchemaStepOne = Yup.object().shape({
  company: Yup.string()
    .min(2, 'მიუთითეთ თქვენი სახელი და გვარი')
    .max(200, 'მიუთითეთ თქვენი სახელი და გვარი')
    .required('მიუთითეთ თქვენი სახელი და გვარი'),
  phone: Yup.string()
    .min(9, 'მიუთითეთ თქვენი ტელ. ნომერი')
    .max(9, 'მიუთითეთ თქვენი ტელ. ნომერი')
    .required('მიუთითეთ თქვენი ტელ. ნომერი'),
  email: Yup.string()
    .email('მიუთითეთ ვარგისი ელ. ფოსტა')
    .matches(emailRegex, 'მიუთითეთ ვარგისი ელ. ფოსტა')
    .required('მიუთითეთ თქვენი ელ. ფოსტა'),
  // location: Yup.string().required('Tell us where you are located'),
  // employees: Yup.string().required('Please select one option'),
});

export default GMCSchemaStepOne;