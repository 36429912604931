import React from 'react';
import NavigatorForm from '../components/forms/navigator';
import {Layout} from "@layout/Layout/Layout";
import GMCLayout from "../layouts/gmc";
import Seo from '@widgets/Seo'

const Navigator = (props) => {
    return (
        <Layout {...props}>
            <Seo title='Tailored Solutions' description='Find expert solutions for your business' />
            <GMCLayout>
                <NavigatorForm/>
            </GMCLayout>
      </Layout>
    );
};

export default Navigator;